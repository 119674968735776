import React, { useRef, useState, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import VisibilitySensor from 'react-visibility-sensor';
import AudioVisualizer from './js/audio-visualization.js';

function AudioVisualizerWrapper(props) {
    const [audioElement, setAudioElement] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const canvasAreaRef = useRef(null);
    const fileInputRef = useRef(null);
    const filterCanvasRef = useRef(null);
    const backgroundRef = useRef(null);
    const scrubBarRef = useRef(null);
    const volumeBarRef = useRef(null);
    const [play, setPlay] = useState(false);
    const [mouseOverBottom1, setMouseOverBottom1] = useState(false);
    const [mouseOverBottom2, setMouseOverBottom2] = useState(false);
    const [mouseOverBottom3, setMouseOverBottom3] = useState(false);
    const [audioVisualizer, setAudioVisualizer] = useState(null);
    const [visualizerLoaded, setVisualizerLoaded] = useState(false);
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        if (audioElement) {
            function updateScrubBar() {
                if(audioElement && scrubBarRef.current) {
                    let currentTime = audioElement.currentTime;
                    let duration = audioElement.duration;
                    let scrubBarValue = (currentTime/duration) * 100;
                    scrubBarRef.current.value = scrubBarValue;
                }
            }
            audioElement.addEventListener("timeupdate", updateScrubBar);
            audioElement.src = (fileInputRef.current && fileInputRef.current.files[0])? URL.createObjectURL(fileInputRef.current.files[0]): process.env.PUBLIC_URL + '/music/song.mp3';
            audioElement.addEventListener("loadeddata", () => {
                updateVolume();
                setLoaded(true);
                if (scrubBarRef && scrubBarRef.current) {
                    scrubBarRef.current.value = 0;
                }
            });
            audioElement.addEventListener("ended", () => {
                setPlay(false);
            });
            audioVisualizer.setAudio(audioElement, () => {if (play) audioElement.play();});
        }
    }, [audioElement]);

    useEffect(() => {
        if (visualizerLoaded) {
            audioVisualizer.resize(dimensions.width, dimensions.height);
        }
    }, [dimensions])

    useEffect(() => {
        if (!visualizerLoaded) {
            setAudioVisualizer(new AudioVisualizer(
                {
                    width: canvasAreaRef.current.offsetWidth,
                    height: canvasAreaRef.current.offsetHeight,
                    foregroundCanvas: filterCanvasRef.current,
                    backgroundCanvas: backgroundRef.current,
                    onLoad: () => {
                        setVisualizerLoaded(true);
                    }
                }
            ));
        }

        function handleResize() {
            setDimensions({
                width: canvasAreaRef.current? canvasAreaRef.current.offsetWidth: dimensions.width,
                height: canvasAreaRef.current? canvasAreaRef.current.offsetHeight : dimensions.height
            });
        };
    
        window.addEventListener('resize', handleResize);
    }, []);

    // Initialize audio context and elements when the file input changes
    function loadAudio() {
        if (fileInputRef.current && fileInputRef.current.files[0]) {
            let filePath = fileInputRef.current.files[0].name;
            var allowedExtensions = /(\.mp3|\.wav|\.mp4)$/i;
            if (!allowedExtensions.exec(filePath)) {
                alert('Invalid file type for audio visualizer. Try again with an MP3, MP4, or WAV file.');
                return;
            }
        }

        if (audioElement) {
            audioElement.pause();
            audioElement.currentTime = 0;
        }

        if (audioElement != null) {
            audioElement.src = "!";
            audioElement.remove();
        }

        audioVisualizer.start();

        setAudioElement(new Audio());
    }

    function updateVolume() {
        if(audioElement && volumeBarRef.current) {
            let volumeBarValue = volumeBarRef.current.value;
            audioElement.volume = volumeBarValue / 100;
        }
    }

    function updatePlayback() {
        if (visualizerLoaded) {
            if (audioElement && scrubBarRef.current) {
                let scrubBarValue = scrubBarRef.current.value;
                let currentTime = (scrubBarValue / 100) * audioElement.duration;
                audioElement.currentTime = currentTime;
            } else if (!audioElement) {
                loadAudio();
            }
        }
    }

    function togglePlay(given) {
        if (visualizerLoaded) {
            if (audioElement) {
                if ((given === true) || audioElement.paused) {
                    audioElement.play();
                    setPlay(true);
                } else {
                    audioElement.pause();
                    setPlay(false);
                }
            } else {
                loadAudio();
                if ((given === true) || !play) {
                    setPlay(true);
                } else {
                    setPlay(false);
                }
            }
        }
    }

    function visibilitySensor(visible) {
        if (visualizerLoaded) {
            audioVisualizer.setActive(visible);
        }
    }

    return (
        <VisibilitySensor onChange={visibilitySensor} partialVisibility={true}>
            <div style={props.style} className='canvas-area no-select' ref={canvasAreaRef}>
                <br />
                <br />
                <div className="main-header-container no-select">
                    <h2 className={"main-header"} style={play?{opacity: 0, transition: "2s opacity"}:{opacity: 0.8, transition: "3s opacity"}}>David Mash</h2>
                </div>
                {visualizerLoaded?
                    <div className="control-panel no-select" onClick={togglePlay}>
                        {play?
                        <img alt={""} src={process.env.PUBLIC_URL + '/images/pause-button.png'} className={"pause-button no-select"} />
                        :
                        <img alt={""} src={process.env.PUBLIC_URL + '/images/play-button.png'} className={"play-button no-select"} />
                        }
                    </div>
                    :
                    <></>
                }
                <br />
                <canvas ref={filterCanvasRef} id="filterCanvas" onClick={visualizerLoaded? togglePlay: () => {}}/>
                <canvas ref={backgroundRef} id="backgroundCanvas" />
                <img
                    alt={""}
                    src={process.env.PUBLIC_URL + '/images/main_images/' + props.imageNumber + '.jpeg'}
                    style={{objectFit: "cover", minHeight: "100%", height: "100%", minWidth: "100%", width: "auto", position: "absolute", zIndex: "-3", top: "0px", left: "0px"}}
                />
                {play || !visualizerLoaded?
                    <></>
                :
                    <div
                        className='bottom-controls-1'
                        onMouseOver={() => {if (!mouseOverBottom1) setMouseOverBottom1(true);}}
                        onMouseLeave={() => {if (mouseOverBottom1) setMouseOverBottom1(false);}}
                        style={mouseOverBottom1?{opacity: 1, transition: "opacity 300ms"}:{opacity: 0.8, transition: "opacity 300ms"}}
                    >
                        <input
                            className={"file-input song-upload-button"}
                            type="file"
                            ref={fileInputRef}
                            onChange={loadAudio}
                        />
                    </div>
                }
                {visualizerLoaded?
                    <>
                        <div
                            className='bottom-controls-2'
                            onMouseOver={() => {if (!mouseOverBottom2) setMouseOverBottom2(true);}}
                            onMouseLeave={() => {if (mouseOverBottom2) setMouseOverBottom2(false);}}
                            style={mouseOverBottom2?{opacity: 0.8, transition: "opacity 300ms"}:play?{opacity: 0.2, transition: "opacity 300ms"}:{opacity: 0.6, transition: "opacity 300ms"}}
                        >
                            <img className="slider-icon-left" src={process.env.PUBLIC_URL + '/images/volume-down.png'} alt=""></img>
                            <input
                                ref={volumeBarRef}
                                className="volume-bar"
                                type="range"
                                min="0"
                                max="100"
                                id="volume-control"
                                defaultValue={100}
                                onInput={() => {updateVolume();}}
                            />
                            <img className="slider-icon-right" src={process.env.PUBLIC_URL + '/images/volume-up.png'} alt=""></img>
                        </div>
                        {loaded?
                            <div
                                className='bottom-controls-3'
                                onMouseOver={() => {if (!mouseOverBottom3) setMouseOverBottom3(true);}}
                                onMouseLeave={() => {if (mouseOverBottom3) setMouseOverBottom3(false);}}
                                style={mouseOverBottom3?{opacity: 0.8, transition: "opacity 300ms"}:play?{opacity: 0.2, transition: "opacity 300ms"}:{opacity: 0.6, transition: "opacity 300ms"}}
                            >
                                <input
                                    ref={scrubBarRef}
                                    className="scrub-bar"
                                    type="range"
                                    min="0"
                                    max="100"
                                    defaultValue={0}
                                    id="scrub-bar"
                                    disabled={!loaded}
                                    onInput={() => {updatePlayback();}}
                                />
                            </div>
                        :
                            <></>
                        }
                    </>
                :
                    <></>
                }
            </div>
        </VisibilitySensor>
    );
}

export default AudioVisualizerWrapper;
