import Animation from './animation';

export default function AudioVisualizer(options) {
    const foregroundCanvas = options.foregroundCanvas;
    const backgroundCanvas = options.backgroundCanvas;
    if (foregroundCanvas === undefined || backgroundCanvas === undefined) {
        console.error("AudioVisualizer requires a foregroundCanvas and backgroundCanvas set in its options.");
    }
    const filterCtx = foregroundCanvas.getContext("2d");
    const baseRender = backgroundCanvas.getContext("2d", { willReadFrequently: true });

    const hiddenCanvas = document.createElement("CANVAS")
    const hiddenCtx =  hiddenCanvas.getContext("2d");
    backgroundCanvas.style.width ='100%';
    backgroundCanvas.style.height='100%';
    foregroundCanvas.style.width ='100%';
    foregroundCanvas.style.height='100%';
    backgroundCanvas.style.transition = "all 2s";
    backgroundCanvas.style.opacity = "0";
    var baseFontSize;

    this.resize = function(width, height) {
        this.width = width || foregroundCanvas.width;
        this.height = height || foregroundCanvas.height;
        backgroundCanvas.width  = width / 8;
        backgroundCanvas.height = height / 8;
        hiddenCanvas.width = width / 8;
        hiddenCanvas.height = height / 8;
        foregroundCanvas.width  = width;
        foregroundCanvas.height = height;
        baseFontSize = height / 60;
    };

    this.resize(options.width, options.height);

    var dataArray;
    var audioCtx, analyser;
    this.setAudio = function(audioElement, callback) {
        if (!audioCtx) {
            audioCtx = new (window.AudioContext || window.webkitAudioContext)();
            audioCtx.resume();
        }
        if (!analyser) {
            analyser = audioCtx.createAnalyser();
        }
        analyser.connect(audioCtx.destination);
        var source = audioCtx.createMediaElementSource(audioElement);
        source.connect(analyser);
        const bufferLength = analyser.frequencyBinCount;
        dataArray = new Uint8Array(bufferLength);
        if (callback) callback();
    }

    let ultimatePeak = 1;
    let rotationAngle = 0;
    let rotateSmudge = false;
    let splitSmudge = true;
    let growSmudge = false;
    let smudgeVelocity = 0;
    let smudgeGrowVelocity = 0;
    var imageData;
    let fontMultiplier = 1, fontMultiplierGoal = 1;
    let increaseDistance = true;
    let distance = 0;

    var stopped = true;
    this.stop = function() {
        stopped = true;
    }

    var vaporwaveAnimation, snakeAnimation, torusAnimation, eyeAnimation;
    this.start = function() {
        // restart all animations
        vaporwaveAnimation.active = false;
        snakeAnimation.active = false;
        torusAnimation.active = false;
        eyeAnimation.active = false;
        snakeAnimation.activeCount = 0;
        eyeAnimation.activeCount = 0;
        eyeAnimation.maxSize = 0;
        // start the draw loop if it isn't already going
        if (!this.started()) {
            stopped = false;
            drawVisualizationLoop();
        }
    }

    this.started = function() {
        return !stopped;
    }

    var active = true;
    this.setActive = function(newActivityStatus) {
        active = newActivityStatus;
    }

    // Draw the frequency spectrum visualization
    function drawVisualizationLoop() {
        if (stopped) return;
        requestAnimationFrame(drawVisualizationLoop);
        if (!active) return;

        if (!analyser) return;
        analyser.getByteFrequencyData(dataArray);
        
        let avgAmplitude = 0;
        let highFreqAvgAmplitude = 0;
        let highFreqPeakAmplitude = 0;
        let uppermidFreqAvgAmplitude = 0;
        let midFreqAvgAmplitude = 0;
        let lowFreqAvgAmplitude = 0;
        let lowFreqPeakAmplitude = 0;
        var gradient;
        if (dataArray && dataArray.length > 0) {
            gradient = baseRender.createLinearGradient(0, 0, baseRender.canvas.width, 0);
            let highFrequencyColor = getHighFrequencyColor(dataArray);
            gradient.addColorStop(0, highFrequencyColor);
            gradient.addColorStop(0.5, getLowFrequencyColor(dataArray));
            gradient.addColorStop(1, highFrequencyColor);
            baseRender.fillStyle = gradient;
            let verticalCenter = baseRender.canvas.height / 2;
            let zeroAdjust = 0;
            for (let i = dataArray.length - 1; i >= 0; i--) {
                if (dataArray[i] - 20 > 0) break;
                zeroAdjust++;
            }

            avgAmplitude = getAverageAmplitude(dataArray, 0, dataArray.length);
            highFreqAvgAmplitude = getAverageAmplitude(dataArray, 8 * dataArray.length / 16, dataArray.length);
            highFreqPeakAmplitude = getPeakAmplitude(dataArray, 8 * dataArray.length / 16, dataArray.length);
            uppermidFreqAvgAmplitude = getAverageAmplitude(dataArray, 3 * dataArray.length / 16, 8 * dataArray.length / 16);
            midFreqAvgAmplitude = getAverageAmplitude(dataArray, dataArray.length / 16, 3 * dataArray.length / 16);
            lowFreqAvgAmplitude = getAverageAmplitude(dataArray, 0, dataArray.length / 16);
            lowFreqPeakAmplitude = getPeakAmplitude(dataArray, 0, dataArray.length / 16);
            if ((vaporwaveAnimation.active && highFreqPeakAmplitude > (highFreqAvgAmplitude + midFreqAvgAmplitude + lowFreqPeakAmplitude) / 4.6) || (avgAmplitude > 100 && highFreqPeakAmplitude > (highFreqAvgAmplitude + midFreqAvgAmplitude + lowFreqPeakAmplitude) / 3.2)) {
                baseRender.clearRect(0, 0, baseRender.canvas.width, baseRender.canvas.height);
                vaporwaveAnimation.active = true;
                vaporwaveAnimation.render(avgAmplitude / 60, baseRender.canvas.width / 2, baseRender.canvas.height / 2, undefined, baseRender.canvas.height, true);
            } else {
                vaporwaveAnimation.active = false;
            }
            let barWidth = ((baseRender.canvas.width / dataArray.length)) / 2;
            let x = 0;
            let x2 = baseRender.canvas.width;
            for (let i = 0; i < dataArray.length - zeroAdjust; i++) {
                drawVisualizationBar(i, barWidth, x, verticalCenter);
                drawVisualizationBar(i, barWidth, x2, verticalCenter);
                x += (baseRender.canvas.width / (dataArray.length - zeroAdjust)) / 2;
                x2 -= (baseRender.canvas.width / (dataArray.length - zeroAdjust)) / 2;
            }
        }

        if (avgAmplitude <= 0) {
            vaporwaveAnimation.active = false;
            snakeAnimation.active = false;
            eyeAnimation.active = false;
        }

        //smudge effect
        rotateSmudge = !vaporwaveAnimation.active && ((rotateSmudge && !(lowFreqPeakAmplitude > ultimatePeak * 0.98) && midFreqAvgAmplitude + uppermidFreqAvgAmplitude + highFreqAvgAmplitude > avgAmplitude * 2.5)
                        || midFreqAvgAmplitude + uppermidFreqAvgAmplitude + highFreqAvgAmplitude > avgAmplitude * 3.5);
        growSmudge = !vaporwaveAnimation.active && (((growSmudge) && highFreqPeakAmplitude * 2 + midFreqAvgAmplitude > highFreqAvgAmplitude + lowFreqAvgAmplitude / 1.8)
                        || (highFreqPeakAmplitude + midFreqAvgAmplitude > highFreqAvgAmplitude + lowFreqAvgAmplitude / 1.5)
                        || (rotateSmudge && highFreqPeakAmplitude + midFreqAvgAmplitude > highFreqAvgAmplitude / 3 + lowFreqAvgAmplitude));
        splitSmudge = !vaporwaveAnimation.active && (!growSmudge || ((splitSmudge && !(lowFreqPeakAmplitude < ultimatePeak * 0.8) && midFreqAvgAmplitude + highFreqAvgAmplitude < avgAmplitude * 3)
                        || midFreqAvgAmplitude + highFreqAvgAmplitude < avgAmplitude * 2));

        torusAnimation.active = false;
        if ((snakeAnimation.active && avgAmplitude > 20 && highFreqPeakAmplitude + uppermidFreqAvgAmplitude > midFreqAvgAmplitude * 0.6) || snakeAnimation.activeCount > (1000 / avgAmplitude)) {
            snakeAnimation.active = true;
            if (midFreqAvgAmplitude > avgAmplitude * 1.8) {
                if (vaporwaveAnimation.active) {
                    let popMultiplier = Math.pow(lowFreqAvgAmplitude / ((midFreqAvgAmplitude + uppermidFreqAvgAmplitude + highFreqAvgAmplitude)), 2);
                    snakeAnimation.modFrameRate(uppermidFreqAvgAmplitude / 5);
                    torusAnimation.active = true;
                    torusAnimation.modFrameRate(uppermidFreqAvgAmplitude / 5);
                    if (increaseDistance) {
                        distance += baseRender.canvas.width / (5000 / avgAmplitude);
                        if (distance > baseRender.canvas.width / 2) increaseDistance = false;
                    } else {
                        distance -= baseRender.canvas.width / (5000 / avgAmplitude);
                        if (distance < -baseRender.canvas.width / 2) increaseDistance = true;
                    }
                    
                    let modifier =  Math.max(((highFreqAvgAmplitude + midFreqAvgAmplitude + lowFreqPeakAmplitude) / 4.6) > 0? highFreqPeakAmplitude / ((highFreqAvgAmplitude + midFreqAvgAmplitude + lowFreqPeakAmplitude) / 4.6) : 0.1, 0.1) / 2;
                    torusAnimation.render(avgAmplitude / 100, baseRender.canvas.width / 2 + (distance * modifier), baseRender.canvas.height / 2, baseRender.canvas.width / 4 * popMultiplier / modifier, undefined, true, 3.14159);
                    torusAnimation.render(avgAmplitude / 100, baseRender.canvas.width / 2 - (distance * modifier), baseRender.canvas.height / 2, baseRender.canvas.width / 4 * popMultiplier / modifier, undefined, true);
                } else {
                    let popMultiplier = Math.pow(highFreqAvgAmplitude * 3 / avgAmplitude, 2);
                    torusAnimation.active = true;
                    torusAnimation.modFrameRate(uppermidFreqAvgAmplitude / 5);
                    if (increaseDistance) {
                        distance += baseRender.canvas.width / (5000 / avgAmplitude);
                        if (distance > baseRender.canvas.width / 2) increaseDistance = false;
                    } else {
                        distance -= baseRender.canvas.width / (5000 / avgAmplitude);
                        if (distance < -baseRender.canvas.width / 2) increaseDistance = true;
                    }
                    let modifier = Math.max((midFreqAvgAmplitude / avgAmplitude * 1.7), 0.001) / 4;
                    torusAnimation.render(avgAmplitude / 100, baseRender.canvas.width / 2 + (distance * modifier), baseRender.canvas.height / 2, baseRender.canvas.width / 4 * popMultiplier / modifier, undefined, true, 3.14159);
                    torusAnimation.render(avgAmplitude / 100, baseRender.canvas.width / 2 - (distance * modifier), baseRender.canvas.height / 2, baseRender.canvas.width / 4 * popMultiplier / modifier, undefined, true);
                }
            } else {
                snakeAnimation.modFrameRate(avgAmplitude / 5);
                if (vaporwaveAnimation.active) {
                    let popMultiplier = Math.pow(lowFreqAvgAmplitude / ((midFreqAvgAmplitude + uppermidFreqAvgAmplitude + highFreqAvgAmplitude)), 2);
                    snakeAnimation.render(avgAmplitude / 30, baseRender.canvas.width / 2, 3 * baseRender.canvas.height / 4, baseRender.canvas.width / 1.3 * popMultiplier, baseRender.canvas.height * popMultiplier, false, -1.5708);
                } else {
                    let popMultiplier = Math.pow(highFreqAvgAmplitude * 3 / avgAmplitude, 2);
                    torusAnimation.render(avgAmplitude / 80, baseRender.canvas.width / 2, baseRender.canvas.height / 2, baseRender.canvas.width / 3 * popMultiplier, undefined, true, -1.5708);
                }
            }
        } else {
            if (avgAmplitude > 40 && highFreqPeakAmplitude + uppermidFreqAvgAmplitude > midFreqAvgAmplitude * 1.7) {
                snakeAnimation.activeCount++;
            } else {
                snakeAnimation.activeCount = 0;
            }
            snakeAnimation.active = false;
            snakeAnimation.reset();
        }

        if (!torusAnimation.active) {
            distance = 0;
            increaseDistance = true;
        }

        if ((eyeAnimation.active && rotateSmudge) || (rotateSmudge && avgAmplitude > 18 && eyeAnimation.activeCount > (100 / avgAmplitude))) {
            eyeAnimation.active = true;
            eyeAnimation.modFrameRate(avgAmplitude);
            eyeAnimation.maxSize = Math.max(midFreqAvgAmplitude / 200, eyeAnimation.maxSize);
            eyeAnimation.render(avgAmplitude / 100, baseRender.canvas.width / 2, baseRender.canvas.height / 2, baseRender.canvas.width / 2 * eyeAnimation.maxSize, undefined, true);
        } else {
            if (rotateSmudge && midFreqAvgAmplitude > (uppermidFreqAvgAmplitude + highFreqAvgAmplitude) * 25) {
                eyeAnimation.activeCount++;
            } else {
                eyeAnimation.activeCount = 0;
                eyeAnimation.maxSize = 0;
            }
            eyeAnimation.active = false;
            eyeAnimation.reset();
        }

        if (rotateSmudge) {
            if (backgroundCanvas && backgroundCanvas.style.opacity !== "0.1") {
                backgroundCanvas.style.opacity = "0.1";
            }
            smudgeVelocity = (lowFreqAvgAmplitude / 64) * ((midFreqAvgAmplitude < highFreqAvgAmplitude * 5)? -1: 1);
            rotationAngle = (rotationAngle + smudgeVelocity) % 360;
            // Save the current canvas state
            baseRender.save();
            // Rotate the canvas
            baseRender.translate(baseRender.canvas.width / 2, baseRender.canvas.height / 2);
            baseRender.rotate(Math.PI / 180 * rotationAngle);
            baseRender.translate(-baseRender.canvas.width / 2, -baseRender.canvas.height / 2);
            // Draw the smudged image on the canvas
            baseRender.globalAlpha = 0.01;
            baseRender.drawImage(baseRender.canvas, 0, 0);
            // Restore the saved state
            baseRender.restore();
        } else if (splitSmudge) {
            rotationAngle = 0;
            if (highFreqPeakAmplitude <= avgAmplitude) {
                if (backgroundCanvas && backgroundCanvas.style.opacity !== "0") {
                    backgroundCanvas.style.opacity = "0";
                }
            } else {
                if (backgroundCanvas && backgroundCanvas.style.opacity !== "0.1") {
                    backgroundCanvas.style.opacity = "0.1";
                }
            }
            imageData = baseRender.getImageData(0, 0, baseRender.canvas.width, baseRender.canvas.height);
            smudgeVelocity = (avgAmplitude < 10)? avgAmplitude * (baseRender.canvas.height / 500): (((smudgeVelocity < 0 && lowFreqPeakAmplitude >= ultimatePeak) || (lowFreqPeakAmplitude >= ultimatePeak && lowFreqAvgAmplitude > (midFreqAvgAmplitude + highFreqAvgAmplitude) * 2))? -1: 1) * avgAmplitude * (baseRender.canvas.height / 1500);
            baseRender.clearRect(0, 0, baseRender.canvas.width, baseRender.canvas.height);
            baseRender.putImageData(imageData, 0, -smudgeVelocity, 0, 0, baseRender.canvas.width, baseRender.canvas.height / 2);
            baseRender.putImageData(imageData, 0, smudgeVelocity, 0, baseRender.canvas.height / 2, baseRender.canvas.width, baseRender.canvas.height / 2);
        }

        if (growSmudge) {
            smudgeGrowVelocity = (((!rotateSmudge && smudgeGrowVelocity < 0 && lowFreqPeakAmplitude > (midFreqAvgAmplitude + highFreqAvgAmplitude) * 2) || lowFreqPeakAmplitude > (midFreqAvgAmplitude + highFreqAvgAmplitude) * 3)? -1: 1) * (highFreqPeakAmplitude - highFreqAvgAmplitude * (rotateSmudge? 4: 1)) * (avgAmplitude / 40000);
            let widthOffset = (baseRender.canvas.width * smudgeGrowVelocity);
            let heightOffset = (baseRender.canvas.height * smudgeGrowVelocity);
            imageData = baseRender.getImageData(0, 0, baseRender.canvas.width, baseRender.canvas.height);
            hiddenCtx.clearRect(0, 0, hiddenCtx.canvas.width, hiddenCtx.canvas.height);
            hiddenCtx.globalAlpha = !rotateSmudge && smudgeGrowVelocity > 0? 0.8 : 0.98;
            hiddenCtx.drawImage(baseRender.canvas, 0, 0);
            baseRender.clearRect(0, 0, baseRender.canvas.width, baseRender.canvas.height);
            baseRender.drawImage(hiddenCtx.canvas, 0 - widthOffset / 2, 0 - heightOffset / 2, baseRender.canvas.width + widthOffset, baseRender.canvas.height + heightOffset);
        } else {
            smudgeGrowVelocity = 0;
        }

        if (avgAmplitude > 40) {
            if ((fontMultiplierGoal >= 1.5 && highFreqAvgAmplitude > avgAmplitude * 2.5) || highFreqAvgAmplitude > avgAmplitude * 3.8) {
                fontMultiplierGoal = 3;
            } else if ((fontMultiplierGoal >= 1.2 && midFreqAvgAmplitude > avgAmplitude * 2) || midFreqAvgAmplitude > avgAmplitude * 3.2) {
                fontMultiplierGoal = 2;
            } else {
                fontMultiplierGoal = 1;
            }
        }
        if (avgAmplitude > 40 && fontMultiplier !== fontMultiplierGoal) {
            fontMultiplier += (fontMultiplierGoal - fontMultiplier) / 16.0;
            if (Math.abs(fontMultiplierGoal - fontMultiplier) < 0.01) {
                fontMultiplier = fontMultiplierGoal;
            }
        }

        convertToASCII(baseRender, filterCtx, fontMultiplier);
    }
    const asciiArtChars = [' ', "'", '^', '*', ';', 'D', 'a', 'v', 'i', 'd', 'M', 'a', 's', 'h', '$', '%', '#', '@', '?', '0', '1', '!'];
    function convertToASCII(fromContext, toContext, fontMultiplier) {
        let fontSize = baseFontSize * fontMultiplier;
        var fromCanvas = fromContext.canvas;
        var toCanvas = toContext.canvas;
        toContext.clearRect(0, 0, toContext.canvas.width, toContext.canvas.height);
        // Set the font for the ASCII art
        toContext.font = fontSize + 'px monospace';
        
        // Get the image data from the original canvas
        var imageData = fromContext.getImageData(0, 0, fromContext.canvas.width, fromContext.canvas.height);
        var pixels = imageData.data;
        var char, r, g, b, alpha, curr, left, right, up, down, fromX, fromY;
        // Convert the image to black and white
        var skip = fontSize, fromSkipX = Math.floor(fontSize / (toCanvas.width / fromCanvas.width)), fromSkipY = Math.floor(fontSize / (toCanvas.height / fromCanvas.height));
        for (let y = 0; y < toCanvas.height; y += skip) {
            fromY = Math.floor(y / (toCanvas.height / fromCanvas.height));
            for (let x = (Math.round(y / skip) % 2 === 0)? 0: fontSize / 2; x < toCanvas.width; x += skip) {
                fromX = Math.floor(x / (toCanvas.width / fromCanvas.width));
                curr = (fromY * fromCanvas.width + fromX) * 4;
                left = (fromY * fromCanvas.width + fromX - (fromSkipX / 2)) * 4;
                right = (fromY * fromCanvas.width + fromX + (fromSkipX / 2)) * 4;
                up = ((fromY - (fromSkipY / 2)) * fromCanvas.width + fromX) * 4;
                down = ((fromY + (fromSkipY / 2)) * fromCanvas.width + fromX) * 4;
                alpha = ((pixels[curr + 3]?pixels[curr + 3]:0) + (pixels[left + 3]?pixels[left + 3]:0) + (pixels[right + 3]?pixels[right + 3]:0) + (pixels[up + 3]?pixels[up + 3]:0) + (pixels[down + 3]?pixels[down + 3]:0)) / 5;
                if (alpha <= 0) continue;
                r = ((pixels[curr]?pixels[curr]:0) + (pixels[left]?pixels[left]:0) + (pixels[right]?pixels[right]:0) + (pixels[up]?pixels[up]:0) + (pixels[down]?pixels[down]:0)) / 5;
                g = ((pixels[curr + 1]?pixels[curr + 1]:0) + (pixels[left + 1]?pixels[left + 1]:0) + (pixels[right + 1]?pixels[right + 1]:0) + (pixels[up + 1]?pixels[up + 1]:0) + (pixels[down + 1]?pixels[down + 1]:0)) / 5;
                b = ((pixels[curr + 2]?pixels[curr + 2]:0) + (pixels[left + 2]?pixels[left + 2]:0) + (pixels[right + 2]?pixels[right + 2]:0) + (pixels[up + 2]?pixels[up + 2]:0) + (pixels[down + 2]?pixels[down + 2]:0)) / 5;
                char = asciiArtChars[Math.floor(asciiArtChars.length * (Math.max(alpha, 1) / 256))];
                // Fill the ASCII character with the original color
                toContext.fillStyle = 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
                toContext.fillText(char, x, y + (fontSize / 2));
            }
        }
    }

    function getAverageAmplitude(data, startIndex, endIndex) {
        startIndex = Math.trunc(startIndex);
        endIndex = Math.min(Math.trunc(endIndex), data.length);
        let sum = 0;
        for (let i = startIndex; i < endIndex; i++) {
            sum += data[i];
        }
        return sum / (endIndex - startIndex - 1);
    }

    function getPeakAmplitude(data, startIndex, endIndex) {
        startIndex = Math.trunc(startIndex);
        endIndex = Math.min(Math.trunc(endIndex), data.length);
        let peak = 0;
        for (let i = startIndex; i < endIndex; i++) {
            peak = Math.max(peak, data[i]);
        }
        return peak;
    }

    function drawVisualizationBar(index, barWidth, x, verticalCenter) {
        if (dataArray[index] > ultimatePeak) {
            ultimatePeak = dataArray[index];
        }
        //take the square of the data like this will amplify peaks
        const adjustedData = 8 * dataArray[index] * Math.pow(dataArray[index] / ultimatePeak, 1.5 * (1 - (index / dataArray.length))) * ((index + (dataArray.length / 2)) / dataArray.length);
        let barHeight = adjustedData * (baseRender.canvas.height / 1024);
        let offsetX = (Math.random() - 0.5) * adjustedData / 128;
        let offsetY = (Math.random() - 0.5) * adjustedData / 128;
        let offsetWidth = (Math.random() - 0.5) * adjustedData / 128;
        baseRender.fillRect(x + offsetX, verticalCenter - (barHeight / 4) + offsetY, barWidth + offsetWidth, barHeight / 2);
    }

    let lowFreqShiftingColorNumber = 0;
    function getLowFrequencyColor(dataArray) {
        let lowFrequencySum = 0;
        let lowFrequencyMax = 0;
        for (let i = 0; i < dataArray.length / 2; i++) {
            lowFrequencySum += dataArray[i];
            if (dataArray[i] > lowFrequencyMax) {
                lowFrequencyMax = dataArray[i];
            }
        }
        let lowFrequencyAverage = lowFrequencySum / (dataArray.length / 2);
        if (lowFrequencyMax > lowFrequencyAverage * 10 || lowFrequencyAverage > ultimatePeak / 2) {
            lowFreqShiftingColorNumber = (lowFreqShiftingColorNumber + (lowFrequencyMax / (lowFrequencyAverage * 20)) + (lowFrequencyAverage / ultimatePeak)) % 255;
        }
        return `hsl(${map(lowFrequencyAverage, lowFreqShiftingColorNumber, 255, lowFreqShiftingColorNumber / 4, 360)}, 100%, 50%,` + (lowFrequencyMax > 0? (lowFrequencyAverage / lowFrequencyMax) : 0) + `)`;
    }

    let highFreqShiftingColorNumber = 0;
    function getHighFrequencyColor(dataArray) {
        let highFrequencySum = 0;
        let highFrequencyMax = 0;
        for (let i = dataArray.length / 2; i < dataArray.length; i++) {
            highFrequencySum += dataArray[i];
            if (dataArray[i] > highFrequencyMax) {
                highFrequencyMax = dataArray[i];
            }
        }
        let highFrequencyAverage = highFrequencySum / (dataArray.length/ 2);
        if (highFrequencyMax > highFrequencyAverage * 200 || highFrequencyAverage > ultimatePeak / 2) {
            highFreqShiftingColorNumber = (highFreqShiftingColorNumber + (highFrequencyMax / (highFrequencyAverage * 200)) + (highFrequencyAverage / ultimatePeak)) % 255;
        }
        return `hsl(${map(highFrequencyAverage, highFreqShiftingColorNumber, 255, highFrequencyAverage / 4, 360)}, 100%, 50%,` + (highFrequencyMax > 0? (highFrequencyAverage / highFrequencyMax) : 0) + `)`;
    }

    function map(value, start1, stop1, start2, stop2) {
        return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
    }

    var onLoad = options.onLoad;
    var animationsLoaded = 0;
    const animationsTotal = 4;
    function animationLoadCallback() {
        if (++animationsLoaded >= animationsTotal) {
            onLoad();
        }
    };

    function init() {
        vaporwaveAnimation = new Animation({
            resource: process.env.PUBLIC_URL + '/images/animation/vaporwave.gif',
            frameRate: 40,
            context: baseRender,
            onLoad: animationLoadCallback
        });

        snakeAnimation = new Animation({
            resource: process.env.PUBLIC_URL + '/images/animation/snake.gif',
            frameRate: 5,
            context: baseRender,
            onLoad: animationLoadCallback
        });

        torusAnimation = new Animation({
            resource: process.env.PUBLIC_URL + '/images/animation/torus.gif',
            frameRate: 40,
            context: baseRender,
            onLoad: animationLoadCallback
        });

        eyeAnimation = new Animation({
            resource: process.env.PUBLIC_URL + '/images/animation/eye.gif',
            frameRate: 1,
            context: baseRender,
            onLoad: animationLoadCallback
        });
    }

    init();
}