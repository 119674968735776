var gifFrames = require('gif-frames');

export default function Animation (options) {
    var resource = options.resource;
    if (!resource) {
        console.error("Animation object must be created with a resource to animate.");
        return;
    }
    var frameRate = options.frameRate || 30;
    var baseFrameRate = frameRate;
    var context = options.context;
    var loaded = false;
    var callback = options.onLoad;

    var images;
    var nextFrameTime;
    var currentFrame;
    
    // =======================
    // PUBLIC
    // =======================
    this.render = function(opacity= 1, drawX = 0, drawY = 0, width, height, scaleToGivenDimension, rotationAngle) {
        if (!loaded) return;
        var now = new Date();
        if (!nextFrameTime) {
            currentFrame = 0;
            determineNextFrame(now);
        } else if (now > nextFrameTime) {
            currentFrame = (currentFrame + 1) % images.length;
            determineNextFrame(now);
        }
        var image = images[currentFrame];
        if (!image) {
            return;
        }
        if (scaleToGivenDimension) {
            if (width && !height) height = image.height * (width / image.width);
            if (height && !width) width = image.width * (height / image.height);
        } else {
            if (!width) width = image.width;
            if (!height) height = image.height;
        }
        context.save()
        context.globalAlpha = opacity;
        if (rotationAngle) {
            context.translate(drawX, drawY);
            context.rotate(rotationAngle);
            context.drawImage(image, 0, 0, image.width, image.height, -width / 2, -height / 2, width, height);
            context.rotate(-rotationAngle);
            context.translate(-(drawX), -(drawY));
        } else {
            context.drawImage(image, 0, 0, image.width, image.height, drawX - width / 2, drawY - height / 2, width, height); 
        }
        context.restore()
    }

    this.setContext = function(newContext) {
        context = newContext;
    }

    this.getWidth = function() {
        return images.length > 0? images[0].width : null;
    }

    this.getHeight = function() {
        return images.length > 0? images[0].height : null;
    }
    
    this.reset = function(frame) {
        nextFrameTime = null;
    }

    this.dispose = function() {
        images = null;
    }

    this.modFrameRate = function(modifier) {
        frameRate = Math.max(baseFrameRate * modifier, 1);
    }

    var init = function() {
        images = [];
        gifFrames({ url: resource, frames: 'all', outputType: 'canvas' },
            function (err, frameData) {
                if (err) {
                    throw err;
                }
                frameData.forEach(function (frame) {
                    images.push(frame.getImage());
                });
                loaded = true;
                callback();
            }
        );
    }

    var determineNextFrame = function(currDate) {
        nextFrameTime = new Date(currDate.getTime() + (1000 / frameRate));
    }
    
    init();
}